<template>
  <!-- style="height: calc(100vh - 140px)" -->
  <v-sheet class="customer" id="customer">
    <v-row>
      <!-- <v-col md="8" class="my-auto">
        <v-layout>
          <v-flex md4 class="my-auto"
            ><h1 class="custom-header-blue-text m-0 text-truncate">{{ pageTitle() }}</h1></v-flex
          >
        </v-layout>
      </v-col>
      <v-col md="4" class="text-right">
        <v-btn class="ml-2" :disabled="pageLoading" depressed tile v-on:click="goBack()">
          Back
        </v-btn>
      </v-col> -->
      <v-col md="12" class="my-auto py-0">
        <v-progress-linear
          indeterminate
          height="6"
          rounded
          v-if="pageLoading"
          class="position-absolute"
          color="blue darken-4"
        ></v-progress-linear>
        <v-container fluid class="my-auto py-0">
          <!-- <v-row v-for="(emp_details, idx) in getEmployeesData" :key="`_$_employee_${idx}`"> -->
          <v-row>
            <!--------- CARD 1 --------->
            <v-col cols="3">
              <v-card :elevation="1" style="height: calc(100vh - 145px); overflow-y: auto">
                <v-card-item class="d-flex justify-space-between align-center pt-2 px-2">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :disabled="pageLoading"
                        depressed
                        fab
                        x-small
                        tile
                        class="rounded-circle"
                        style="cursor: pointer"
                        v-on:click="goBack()"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon class="pa-1">mdi-arrow-left</v-icon>
                      </v-btn>
                    </template>
                    <span>Back</span>
                  </v-tooltip>
                </v-card-item>

                <v-card-item class="d-flex justify-center pt-3">
                  <ImageTemplate
                    :src="getEmployeesData?.images?.img_link"
                    :maxWidth="150"
                    :maxHeight="150"
                    :aspectRatio="1"
                    :contain="false"
                    circle
                  />
                </v-card-item>

                <v-card-item class="d-flex flex-column justify-center align-center">
                  <v-card-title class="font-weight-bold text-h5">
                    {{ getEmployeesData?.name?.value }}</v-card-title
                  >
                </v-card-item>
                <v-divider class="mt-0"></v-divider>
                <!-- d-flex flex-row align-center -->
                <v-flex class="d-flex flex-row align-center">
                  <v-col md="6" class="">
                    <v-card-item>
                      <p style="font-weight: bold; font-size: 15px">EMPLOYEE ID</p>
                      <p style="font-weight: bold; font-size: 15px">ROLE</p>
                    </v-card-item>
                  </v-col>
                  <v-col md="4" class="">
                    <v-card-item>
                      <p class="gray" style="font-size: 15px">
                        <v-chip color="#0D47A1" class="text-white" small label>
                          <ValueTemplate
                            :value="getEmployeesData?.id?.value"
                            title="Id"
                          ></ValueTemplate>
                          <!-- {{ getEmployeesData?.id?.value }} -->
                        </v-chip>
                      </p>
                      <p class="gray" style="font-size: 15px">
                        <ValueTemplate
                          :value="getEmployeesData?.role?.value"
                          title="role"
                        ></ValueTemplate>
                        <!-- {{ getEmployeesData?.role?.value }} -->
                      </p>
                    </v-card-item>
                  </v-col>
                </v-flex>
                <h5 class="custom-header-blue-text px-3">ID DETAILS</h5>
                <v-divider class="mt-0"></v-divider>
                <v-flex class="d-flex flex-row align-center">
                  <v-col md="6" class="">
                    <v-card-item>
                      <p style="font-weight: bold; font-size: 15px">COUNTRY</p>
                      <p style="font-weight: bold; font-size: 15px">MY KAD</p>
                      <p style="font-weight: bold; font-size: 15px">PASSPORT</p>
                      <p style="font-weight: bold; font-size: 15px">VISA</p>
                    </v-card-item>
                  </v-col>
                  <v-col md="4" class="">
                    <v-card-item>
                      <p class="gray lighten-4">{{ getEmployeesData?.country?.value }}</p>
                      <p class="gray lighten-4">
                        <ValueTemplate
                          :value="getEmployeesData?.mykad?.value"
                          title="My kad"
                        ></ValueTemplate>
                        <!-- {{ getEmployeesData?.mykad?.value }} -->
                      </p>
                      <p class="gray lighten-4">
                        <ValueTemplate
                          :value="getEmployeesData?.passport?.value"
                          title="passport"
                        ></ValueTemplate>
                        <!-- {{ getEmployeesData?.passport?.value }} -->
                      </p>
                      <p class="gray lighten-4">
                        <ValueTemplate
                          :value="getEmployeesData?.visa?.value"
                          title="visa"
                        ></ValueTemplate>
                        <!-- {{ getEmployeesData?.visa?.value }} -->
                      </p>
                    </v-card-item>
                  </v-col>
                </v-flex>
                <v-card-item class="d-flex flex-column align-center" style="overflow-y: auto">
                </v-card-item>
              </v-card>
            </v-col>

            <!--------- CARD 2 --------->
            <v-col cols="9">
              <v-card style="height: calc(100vh - 145px); overflow-y: auto">
                <!-- <v-card-item> -->
                <v-col md="12" class="my-auto py-0">
                  <v-tabs
                    v-model="employeeTab"
                    background-color="transparent"
                    color="blue darken-4"
                    class="custom-tab-transparent"
                    active-class="blue darken-4 text-white"
                    hide-slider
                  >
                    <v-tab href="#overview"> OVERVIEW (1) </v-tab>
                    <v-tab href="#salary"> SALARY (2) </v-tab>
                    <v-tabs-items v-model="employeeTab">
                      <v-tab-item value="overview">
                        <v-card-title class="text-h5" style="font-weight: bold"
                          >BASIC DETAILS</v-card-title
                        >
                        <v-divider class="my-auto py-0"></v-divider>
                        <v-col class="my-auto py-0">
                          <v-tab-item value="overview">
                            <v-col md="12">
                              <v-row>
                                <v-col md="3">
                                  <p>
                                    <span style="font-weight: bold; font-size: 15px"
                                      >JOINING DATE:</span
                                    >
                                  </p>
                                  <p>
                                    <span style="font-weight: bold; font-size: 15px"
                                      >DEPARTMENT:</span
                                    >
                                  </p>
                                  <p>
                                    <span style="font-weight: bold; font-size: 15px">EMAIL:</span>
                                  </p>
                                  <p>
                                    <span style="font-weight: bold; font-size: 15px"
                                      >PHONE NUMBER:</span
                                    >
                                  </p>
                                </v-col>
                                <v-col md="3">
                                  <p>
                                    <span class="gray" style="font-size: 15px">
                                      <ValueTemplate
                                        :value="getEmployeesData?.joining_date?.value"
                                        title="Joining Date"
                                      ></ValueTemplate>
                                      <!-- {{ getEmployeesData?.joining_date?.value }} -->
                                    </span>
                                  </p>
                                  <p>
                                    <span class="gray" style="font-size: 15px">
                                      <ValueTemplate
                                        :value="getEmployeesData?.department?.value"
                                        title="department"
                                      ></ValueTemplate>
                                      <!-- {{ getEmployeesData?.department?.value }} -->
                                    </span>
                                  </p>
                                  <p>
                                    <span class="gray" style="font-size: 15px">
                                      <ValueTemplate
                                        :value="getEmployeesData?.email?.value"
                                        title="email"
                                      ></ValueTemplate>
                                      <!-- {{getEmployeesData?.email?.value}} -->
                                    </span>
                                  </p>
                                  <p>
                                    <span class="gray" style="font-size: 15px">
                                      <ValueTemplate
                                        :value="getEmployeesData?.phone_no?.value"
                                        title="phone no"
                                      ></ValueTemplate>
                                      <!-- {{getEmployeesData?.phone_no?.value}} -->
                                    </span>
                                  </p>
                                </v-col>
                              </v-row>
                            </v-col>
                            <v-card-title class="text-h5 px-0 pt-0" style="font-weight: bold"
                              >SALARY DETAILS</v-card-title
                            >
                          </v-tab-item>
                        </v-col>
                        <v-divider class="my-auto py-0"></v-divider>
                        <v-col class="my-auto py-0">
                          <v-tab-item value="overview">
                            <v-col md="12">
                              <v-row>
                                <v-col md="3">
                                  <p>
                                    <span style="font-weight: bold; font-size: 15px"
                                      >PROBATION PERIOD:</span
                                    >
                                  </p>
                                  <p>
                                    <span style="font-weight: bold; font-size: 15px"
                                      >SALARY DURING PROBATION:</span
                                    >
                                  </p>
                                  <p>
                                    <span style="font-weight: bold; font-size: 15px"
                                      >SALARY AFTER PROBATION:</span
                                    >
                                  </p>
                                  <p>
                                    <span style="font-weight: bold; font-size: 15px"
                                      >MONTHLY SALARY DATE:</span
                                    >
                                  </p>
                                </v-col>
                                <v-col md="5">
                                  <p>
                                    <span class="gray" style="font-size: 15px">
                                      <ValueTemplate
                                        :value="getEmployeesData?.probation_period?.value"
                                        title="probation period"
                                      ></ValueTemplate>
                                      <!-- {{ getEmployeesData?.probation_period?.value }}-->
                                    </span>
                                  </p>
                                  <p>
                                    <span class="gray" style="font-size: 15px">
                                      <ValueTemplate
                                        :value="getEmployeesData?.salar_during_probation_rm?.value"
                                        title="salary probation"
                                      ></ValueTemplate>
                                      <!-- {{ getEmployeesData?.salar_during_probation_rm?.value }} -->
                                    </span>
                                  </p>
                                  <p>
                                    <span class="gray" style="font-size: 15px">
                                      <ValueTemplate
                                        :value="getEmployeesData?.after_prob_salary_rm?.value"
                                        title="salary after probation"
                                      ></ValueTemplate>
                                      <!-- {{ getEmployeesData?.after_prob_salary_rm?.value }} -->
                                    </span>
                                  </p>
                                  <p>
                                    <span class="gray" style="font-size: 15px">
                                      <ValueTemplate
                                        :value="getEmployeesData?.monthly_salary_rm?.value"
                                        title="monthly salary"
                                      ></ValueTemplate>
                                      <!-- {{getEmployeesData?.monthly_salary_rm?.value}} -->
                                    </span>
                                  </p>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-tab-item>
                        </v-col>
                      </v-tab-item>
                      <v-tab-item value="salary">
                        <v-row>
                          <v-col md="6">
                            <v-card-title
                              class="d-flex flex-row justify-content-between text-h5 px-0"
                              style="font-weight: bold"
                              >SALARY DETAILS</v-card-title
                            >
                          </v-col>
                          <v-flex class="d-flex" align="end">
                            <v-col class="py-0">
                              <label
                                for="segment_name"
                                class="custom-form-label"
                                style="font-weight: 600"
                                >Select Month</label
                              >
                              <SelectInput
                                hide-details
                                :disabled="pageLoading"
                                :loading="pageLoading"
                                :items="['All', 'ACTIVE', 'IN-ACTIVE']"
                                :subTitle="value"
                                id="salutation"
                                placeholder="Select"
                              ></SelectInput>
                            </v-col>
                            <v-col class="py-0">
                              <label
                                for="segment_name"
                                class="custom-form-label"
                                style="font-weight: 600"
                                >Filter by Month</label
                              >
                              <SelectInput
                                hide-details
                                :disabled="pageLoading"
                                :loading="pageLoading"
                                :items="['January', 'February', 'March', 'April']"
                                id="segment"
                                item-text="name"
                                item-value="id"
                                placeholder="Select Month"
                              ></SelectInput>
                            </v-col>
                          </v-flex>
                        </v-row>
                        <v-divider class="my-auto py-0"></v-divider>
                        <SalaryEmployee :salaryDetalsList="getEmployeesData"></SalaryEmployee>
                        <!-- <SupplierContactPerson :employeeEdit="employeeEdit"></SupplierContactPerson> -->
                      </v-tab-item>
                    </v-tabs-items>
                  </v-tabs>
                </v-col>
                <!-- </v-card-item> -->
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <OrderTemplate
      v-model="order"
      :customer-id="customerId"
      :dialog="orderDialog"
      v-on:close="orderDialog = false"
      v-on:save="getOrders()"
      v-on:collect:voucher="getAvailableVouchers"
      v-on:claim:voucher="getCollectedVouchers"
    ></OrderTemplate>
    <DeleteTemplate
      type="Order"
      :delete-text="deleteText"
      delete-note="All transactions of this order will also be deleted."
      :delete-dialog="deleteDialog"
      :delete-endpoint="deleteEndpoint"
      v-on:close="deleteDialog = false"
      v-on:delete:success="getOrders()"
    ></DeleteTemplate>
    <DeleteTemplate
      type="Customer"
      :delete-text="cDeleteText"
      delete-note="All transactions of this customer will also be deleted."
      :delete-dialog="cDeleteDialog"
      :delete-endpoint="cDeleteEndpoint"
      v-on:close="cDeleteDialog = false"
      v-on:delete:success="goBack()"
    ></DeleteTemplate>
    <CollectTemplate
      :dialog="collectDialog"
      :order-id="cOrderId"
      :order-code="cOrderCode"
      :vouchers="cVouchers"
      v-on:close="vCollected()"
      v-on:collected="vCollected()"
    ></CollectTemplate>
    <RedeemTemplate
      :dialog="redeemDialog"
      :order-id="rOrderId"
      :order-code="rOrderCode"
      :vouchers="rVouchers"
      v-on:close="vRedeemed()"
      v-on:redeemed="vRedeemed()"
    ></RedeemTemplate>
  </v-sheet>
</template>

<script>
import { toSafeInteger } from "lodash";
// import Chip from "@/view/components/Chip";
import ValueTemplate from "@/view/components/ValueTemplate";
import OrderTemplate from "@/view/components/OrderTemplate";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import CollectTemplate from "@/view/components/CollectVoucherTemplate";
import RedeemTemplate from "@/view/components/RedeemVoucherTemplate";
import ImageTemplate from "@/view/components/Image";
// import TextInput from "@/view/components/TextInput";
import SelectInput from "@/view/components/SelectInput";
import SalaryEmployee from "@/view/components/SalaryEmployee";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_CUSTOMER } from "@/core/lib/customer.lib";
import { GET_ORDERS } from "@/core/lib/order.lib";
import { SET_ERROR } from "@/core/services/store/common.module";
import {
  COMMON_EVENT_BUS,
  GET_AVAILABLE_VOUCHERS,
  GET_COLLECTED_VOUCHERS,
} from "@/core/lib/common.lib";
import { format } from "date-fns";
// import SupplierContactPerson from "../../components/SupplierContactPerson.vue";

export default {
  name: "customer-detail",
  title: "Detail Customer",
  data() {
    return {
      employeeEdit: true,
      speedDial: [],
      cLoading: false,
      rLoading: false,
      cDeleteText: null,
      cDeleteDialog: false,
      cDeleteEndpoint: null,
      deleteDialog: false,
      deleteEndpoint: null,
      deleteText: null,
      pageLoading: true,
      orderDialog: false,
      collectDialog: false,
      redeemDialog: false,
      customerId: null,
      employeeTab: "overview",
      currentPage: 1,
      pageLimit: 1,
      showingFrom: 0,
      showingTo: 0,
      totalRows: 0,
      search: null,
      order: {
        id: null,
        date: format(new Date(), "yyyy-MM-dd"),
        time: format(new Date(), "HH:mm"),
        bill_no: null,
        amount: null,
        voucher: "none",
      },
      employee_details: [],
      customer: {
        barcode: null,
        title: null,
        first_name: null,
        last_name: null,
        full_name: null,
        email: null,
        date_of_birth: null,
        phone_number: null,
        address_line_1: null,
        address_line_2: null,
        unit_number: null,
        postal_code: null,
        country: null,
        total_orders: null,
        total_vcollected: null,
        total_vredeemed: null,
        total_vexpired: null,
        added_by: null,
        added_at: null,
        updated_by: null,
        updated_at: null,
      },
      orders: [],
      cVouchers: [],
      cOrderId: 0,
      cOrderCode: null,
      rOrderId: 0,
      rVouchers: [],
      rOrderCode: null,
      getEmployeesData: [],
    };
  },
  watch: {
    currentPage() {
      this.getOrders();
    },
  },
  methods: {
    async getAvailableVouchers(orderId, barcode) {
      try {
        this.cOrderId = orderId;
        this.cOrderCode = barcode;
        this.cLoading = true;
        this.cVouchers = await GET_AVAILABLE_VOUCHERS(orderId);
        this.collectDialog = true;
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.cLoading = false;
      }
    },
    async getCollectedVouchers(orderId, barcode) {
      try {
        this.rOrderId = orderId || 0;
        this.rOrderCode = barcode || null;
        this.rLoading = true;
        this.rVouchers = await GET_COLLECTED_VOUCHERS(this.customerId);
        this.redeemDialog = true;
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.rLoading = false;
      }
    },
    vCollected() {
      this.cOrderId = 0;
      this.cOrderCode = null;
      this.cVouchers = [];
      this.collectDialog = false;
      this.getOrders();
    },
    vRedeemed() {
      this.rOrderId = 0;
      this.rOrderCode = null;
      this.rVouchers = [];
      this.redeemDialog = false;
      this.getOrders();
    },
    deleteCustomerConfirm() {
      this.cDeleteText = this.customer.barcode;
      this.cDeleteDialog = true;
      this.cDeleteEndpoint = `customer/${this.customerId}`;
    },
    deleteConfirm(row) {
      this.deleteDialog = true;
      this.deleteText = row.barcode;
      this.deleteEndpoint = `customer/${this.customerId}/order/${row.id}`;
    },
    clearVars() {
      this.orderDialog = false;
      this.deleteDialog = false;
      this.deleteText = null;
      this.deleteEndpoint = null;
      this.order = {
        id: null,
        date: format(new Date(), "yyyy-MM-dd"),
        time: format(new Date(), "HH:mm"),
        bill_no: null,
        amount: null,
        voucher: "none",
      };
    },
    pageTitle() {
      if (this.customer.full_name) {
        return this.customer.full_name;
      }
      return "Detail Employee";
    },
    routeToUpdate(row) {
      this.order = {
        id: row.id,
        date: row.date,
        time: row.time,
        bill_no: row.bill_no,
        amount: row.amount,
        voucher: row.voucher,
      };
      this.orderDialog = true;
    },
    async getEmployeeDetails() {
      const _this = this;
      try {
        const customer = await GET_CUSTOMER(_this.customerId);
        _this.getOrders();
        _this.customer = customer;
        _this.$store.dispatch(SET_BREADCRUMB, [
          { text: "Customer", disabled: true },
          { text: "Detail", disabled: true },
          { text: customer.barcode, disabled: true },
        ]);
      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        _this.pageLoading = false;
      }
    },
    async getOrders() {
      const _this = this;
      const form = {
        current_page: _this.currentPage,
        search: _this.search,
      };
      try {
        _this.pageLoading = true;
        const { rows, totalPages, showingFrom, showingTo, totalRows } = await GET_ORDERS(
          _this.customerId,
          form
        );
        _this.clearVars();
        _this.orders = rows;
        _this.pageLimit = totalPages;
        _this.showingFrom = showingFrom;
        _this.showingTo = showingTo;
        _this.totalRows = totalRows;
        COMMON_EVENT_BUS.$emit("orders:loaded", true);
      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        _this.pageLoading = false;
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { text: "Customer", disabled: true },
      { text: "Detail", disabled: true },
    ]);

    // const { name, params } = this.$route;
    // if (name === "employee-detail") {
    //   this.getEmployeesData = params.row;
    // } else {
    //   this.goBack();
    // }

    const { id, row } = this.$route.params;
    if (id && row) {
      this.customerId = toSafeInteger(id);
      this.getEmployeesData = row;
      this.getEmployeeDetails();
    } else {
      this.goBack();
    }
  },
  components: {
    // Chip,
    // TextInput,
    ValueTemplate,
    // AvailableTemplate,
    SelectInput,
    ImageTemplate,
    SalaryEmployee,
    CollectTemplate,
    RedeemTemplate,
    DeleteTemplate,
    OrderTemplate,
    // SupplierContactPerson
  },
};
</script>
